import * as React from "react";
import { Layout, Menu, PageTitle } from "../../components";
import Seo from "../../components/Seo";

const MenuPage = () => {
  return (
    <Layout>
      <Seo title="MENU - 提供するサービス" />
      <PageTitle title="MENU" subTitle="メニュー" />
      <Menu />
    </Layout>
  );
};

export default MenuPage;
